import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BLACK, GRAY_05, WHITE } from '../../constants/colors';
import { VetItemType } from '../../data/vet';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface VetCardItemProps {
	itemData: VetItemType;
}

const VetCardItem = ({ itemData }: VetCardItemProps) => {
	const { name, position, additionalDescription, careerDesktop, career, treatmentSubjects, image } = itemData;

	return (
		<CardWrapper>
			<DesktopVetImage src={image} alt="" />
			<ContentAreaDesktop>
				<NameWrapper>
					<Name>
						{name}
						<Position>{position}</Position>
					</Name>
					{additionalDescription && <Additional>{additionalDescription}</Additional>}
				</NameWrapper>
				<DetailsWrapper>
					<Careers>
						{careerDesktop.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</Careers>
					<SubjectsTitle>
						<p>진료과목</p>
						<Line />
					</SubjectsTitle>
					<Subjects>
						{treatmentSubjects.map((subject, index) => (
							<Subject key={index}>{subject}</Subject>
						))}
					</Subjects>
				</DetailsWrapper>
			</ContentAreaDesktop>
			<ContentAreaMobile>
				<ContentMobile>
					<MobileVetImage src={image} alt="" />
					<VetDetailMobile>
						<NameWrapper>
							<Name>
								{name}
								<Position>{position}</Position>
							</Name>
							{additionalDescription && <Additional>{additionalDescription}</Additional>}
						</NameWrapper>
						<DetailsWrapper>
							<Careers>
								{career.map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</Careers>
						</DetailsWrapper>
					</VetDetailMobile>
				</ContentMobile>
				<SubjectsTitle>
					<p>진료과목</p>
					<Line />
				</SubjectsTitle>
				<Subjects>
					{treatmentSubjects.map((subject, index) => (
						<Subject key={index}>{subject}</Subject>
					))}
				</Subjects>
			</ContentAreaMobile>
		</CardWrapper>
	);
};

export default VetCardItem;

const Subject = styled.li`
	padding: 0 ${pxToRem(7)};
	background-color: ${GRAY_05};
	border-radius: ${pxToRem(7.5)};
	height: ${pxToRem(15)};
	line-height: ${pxToRem(15)};
	font-size: ${pxToRem(9)};
	color: ${BLACK};
	margin-right: ${pxToRem(8)};
	margin-bottom: ${pxToRem(7)};

	${getDesktopStyle(css`
		padding: 0 ${pxToRem(10)};
		border-radius: ${pxToRem(10)};
		height: ${pxToRem(20)};
		line-height: ${pxToRem(19)};
		font-size: ${pxToRem(11)};
		margin-bottom: ${pxToRem(8)};
	`)}
`;

const Careers = styled.ul`
	li {
		font-size: ${pxToRem(10.5)};
		line-height: ${pxToRem(19)};
		letter-spacing: ${pxToRem(-0.5)};
		font-weight: 500;
		white-space: pre-line;
	}

	${getDesktopStyle(css`
		margin-bottom: ${pxToRem(32)};

		li {
			font-size: ${pxToRem(14)};
			line-height: ${pxToRem(26)};
		}
	`)}
`;

const Subjects = styled.ul`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${pxToRem(8)};

	${getDesktopStyle(css`
		margin-top: ${pxToRem(4)};
	`)}
`;

const Line = styled.hr`
	flex: 1;
	height: 0.5px;
	background-color: ${BLACK};
	margin-left: ${pxToRem(15)};

	${getDesktopStyle(css`
		height: 1px;
		margin-left: ${pxToRem(8)};
	`)}
`;

const SubjectsTitle = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${pxToRem(8)};
	font-size: ${pxToRem(11)};
	margin-bottom: ${pxToRem(8)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(12)};
	`)}
`;

const DetailsWrapper = styled.div`
	font-size: ${pxToRem(10.5)};
	line-height: ${pxToRem(19)};
	word-break: keep-all;

	${getDesktopStyle(css`
		font-size: ${pxToRem(12)};
		line-height: ${pxToRem(20)};
	`)}
`;

const Additional = styled.p`
	line-height: ${pxToRem(18)};
	height: ${pxToRem(18)};
	padding: 0 ${pxToRem(8)};
	color: ${WHITE};
	font-size: ${pxToRem(10.5)};
	background-color: ${BLACK};
	border-radius: ${pxToRem(9)};
	display: inline-block;

	${getDesktopStyle(css`
		line-height: ${pxToRem(23)};
		height: ${pxToRem(28)};
		padding: ${pxToRem(2)} ${pxToRem(16)} ${pxToRem(3)};
		font-size: ${pxToRem(16)};
		border-radius: ${pxToRem(15)};
		margin-bottom: ${pxToRem(40)};
		letter-spacing: ${pxToRem(-0.5)};
	`)}
`;

const Position = styled.span`
	font-size: ${pxToRem(14)};
	margin-left: ${pxToRem(8)};
	font-weight: 400;

	${getDesktopStyle(css`
		font-size: ${pxToRem(20)};
		line-height: ${pxToRem(28.96)};
		margin-left: ${pxToRem(12)};
	`)}
`;

const Name = styled.p`
	font-size: ${pxToRem(20)};

	${getDesktopStyle(css`
		font-size: ${pxToRem(30)};
		line-height: ${pxToRem(43.44)};
	`)}
`;

const NameWrapper = styled.div`
	margin-bottom: ${pxToRem(6)};
	line-height: ${pxToRem(29)};

	${getDesktopStyle(css`
		margin-bottom: ${pxToRem(8)};
		line-height: ${pxToRem(38)};
	`)}
`;

const MobileVetImage = styled.img`
	${showOnMobileStyle}
	width: 37.3vw;
	height: 67.7vw;
	object-fit: contain;
	margin-right: ${pxToRem(12)};
`;

const DesktopVetImage = styled.img`
	${showOnDesktopStyle}
	width: ${pxToRem(337)};
	height: ${pxToRem(531)};
	clip-path: inset(0 0 ${pxToRem(-40)} 0);
	bottom: ${pxToRem(-64)};
	left: ${pxToRem(40)};
	margin-right: 0;
`;

const VetDetailMobile = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: ${pxToRem(20)};
`;

const ContentMobile = styled.div`
	display: flex;
`;

const ContentAreaMobile = styled.div`
	${showOnMobileStyle};
`;

const ContentAreaDesktop = styled.div`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		flex: 1;
		padding: 0 ${pxToRem(48)} 0 ${pxToRem(96)};
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: ${pxToRem(531)};
		border-radius: 0px 20px 0px 0px;
	`)}
`;

const CardWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: ${pxToRem(-15)};

	${getDesktopStyle(css`
		position: relative;
		display: flex;
		justify-content: center;
		width: ${pxToRem(863)};
		margin: ${pxToRem(24)} auto 0;
	`)}
`;
